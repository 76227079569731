import { combineReducers } from "redux";
import surveysReducer from "./surveysReducer";
import surveyQuestionReducer from "./surveyQuestionReducer";
import activateCampaignReducer from "./activateSurveyReducer";
import loadActiveSurveyReducer from "./loadActiveSurveyReducer";
import userEmailReducer from "./userEmailReducer";
import surveyResponseReducer from "./surveyResponceReducer";
import contactUsReducer from "./contactUsReducer";
import campaignCountReducer from "./campaignCountReducer";
import contusResponcesReducer from "./contactusResponcesReducer";
import userEmailResponseReducer from "./userEmailResponseReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import surveyCampaignHeader from "./surveyCampaignHeaderReducer";
import studioReducer from "./studioReducer";
import projectReducer from "./projectReducer";
import productReducer from "./productReducer";

export default combineReducers({
  surveys: surveysReducer,
  campaign: surveyQuestionReducer,
  activateCampaign: activateCampaignReducer,
  loadActiveSurvey: loadActiveSurveyReducer,
  userEmail: userEmailReducer,
  surveyResult: surveyResponseReducer,
  contactUs: contactUsReducer,
  campaignCount: campaignCountReducer,
  contactUsResponces: contusResponcesReducer,
  userEmailResponse: userEmailResponseReducer,
  auth: authReducer,
  errors: errorReducer,
  surveyCampaignColHeader : surveyCampaignHeader,
  studio : studioReducer,
  project : projectReducer,
  product : productReducer
});
