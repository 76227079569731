import React from 'react';
import ContactUs from '../../components/Contactus/index';
import PLL from '../../components/Product/ProductLandingLayout';
import '../../App.css';
import 'antd/dist/antd.css';
export default class frmContactUS extends React.Component 
{
    render()
    {
        return(
            <div>
            <PLL>
            <ContactUs />
            </PLL>
            </div>
        );
    }
}