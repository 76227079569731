import React from "react";
import {Divider} from "antd";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
import DashboardLayout from "../../components/Dashboard/dashboardLayout";
import NewCSuvey from "../../components/Dashboard/addNewSurveyCard";
import Cards from "../../components/Dashboard/allCampaignCards";
// import { logoutUser } from "../../actions";


export default  class dbHome extends React.Component {




componentDidUpdate() {
  //this.props.fetchSurveys();
}

renderSurveys() {
  
 return(
    <div>

  <DashboardLayout>
  <NewCSuvey campaign={0}/>
  <Divider />
  <div>
  <Cards />
</div>
  </DashboardLayout>
  </div>
  
  );

}
  render() {

    return (
      <div>
        {this.renderSurveys()}
      </div>
   );
  }
}


// dbHome.propTypes = {
//   auth: PropTypes.object.isRequired
// };

// const mapStateToProps = state => ({
//   auth: state.auth
// });
// connect(
//   mapStateToProps,
//   { logoutUser }
// )(dbHome);