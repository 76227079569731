import React from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Typography,
  message
} from "antd";
import "./index.css";
import { addContctus } from "../../actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

const { Title,Text } = Typography;
const { Option } = Select;

const formlayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};
const tailLayout = {
  //wrapperCol: { offset: 8, span: 16 },
};

class ContactUs extends React.Component {
  formRef = React.createRef();

  ContactInfo = (values) => {
    this.props.addContctus(values);
  };

  renderContactInfo() {
    if (this.props.contactUs.contactUs.message !== undefined) {
      message.info(this.props.contactUs.contactUs.message, 1);
      this.formRef.current.resetFields();
    }
    return (
      <div className="container-contact100">
        <p style={{color: "#646464",margin: "0% 15% -4% 15%",fontSize: "20px"}}><Text strong>Planning is bringing the future into the present,so that you can do something about it now.</Text></p>
        <div className="wrap-contact100">
        {/* <p style={{color: "#abb2b9",margin: "2% 29% 0% 0%",fontSize: "20px"}}>Planning is bringing the future into the present,<br/> so that you can do something about it now.</p> */}
        {/* <Divider /> */}
          <Form
            {...formlayout}
            ref={this.formRef}
            layout="vertical"
            name="control-ref"
            style={{ textAlign: "center", padding: "2%", width: "100%" }}
            onFinish={(values) => {
              this.ContactInfo(values);
            }}
          >
            <Row>
              <Col
                xs={{ span: 18 }}
                md={{ span: 12 }}
                xl={{ span: 12 }}
                className="article image overlay"
              >
                <div className="flex-col size2">
                  <span className="txt1 p-b-20"></span>
                </div>

                {/* <div className="flex-col size2">
                  <span className="txt1 p-b-20">Address</span>

                  <span className="txt2">
                    Mada Center 8th floor, 379 Hudson St, New York, NY 10018 US
                  </span>
                </div> */}
                <div className="flex-col size2">
                  <span
                    className="p-b-20"
                    style={{ color: "#ffffff", fontSize: "30px" }}
                  >
                    We'd love to hear from you.
                  </span>
                  <span
                    className="p-b-20"
                    style={{ color: "#ffffff", fontSize: "18px" }}
                  >
                    Please contact us using the form, or alternatively,you can email us.
                  </span>
                </div>
                <div className="flex-col size2">
                  <span className="txt1 p-b-20"></span>
                </div>
                <div className="flex-col size2">
                  <span className="txt1 p-b-20">Address</span>
                  <span className="txt3">adhstudio<br/>Green Residency,<br/>Adajan, Surat - 395009.</span>
                </div>
                <div className="flex-col size2">
                  <span className="letstalk p-b-20">Call us</span>
                  <span className="txt3">+91-7575027019</span>
                </div>
                <div className="flex-col size2">
                  <span className="txt1 p-b-20"></span>
                </div>
                <div className="flex-col size2">
                  <span className="txt1 p-b-20">Send an email</span>
                  <span className="txt3">consult@discoverhomes.co.in</span>
                </div>
              </Col>
              <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                <div style={{ width: "100%", margin: "10%" }}>
                  <Title level={4} style={{ marginLeft: "-10%" }}>
                    Submit your query
                  </Title>
                  {/* <p style={{ marginLeft: "-10%" }}>Planning is bringing the future into the present,<br/> so that you can do something about it now.</p> */}
                  <Form.Item
                    name="firstname"
                    label="First Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="lastname"
                    label="Last Name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                      {
                        required: true,
                        // message: 'Please input your E-mail!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="requestquotation"
                    label="Request Quotation"
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder="Select a option and change input text above"
                      allowClear
                    >
                      {/* <Option value="RequestQuotation">Request Quotation</Option> */}
                      <Option value="Homes">Homes</Option>
                      <Option value="Carpet">Carpet</Option>
                      <Option value="Budget">Budget</Option>
                      <Option value="Partner">Partner</Option>
                      <Option value="VenderRegistration">
                        Vender Registration
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="message"
                    label="Message"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderContactInfo()}</div>;
  }
}

function mapStateToProps(contactUs) {
  return { contactUs };
}

export default withRouter(connect(mapStateToProps, { addContctus })(ContactUs));
