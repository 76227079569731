import React from 'react';
import ViewProducts from '../../components/Products/viewProducts';
import PLL from '../../components/Product/ProductLandingLayout';
import '../../App.css';
import 'antd/dist/antd.css';
export default class frmContactUS extends React.Component 
{
    render()
    {
        return(
            <div>
            <PLL>
            <ViewProducts />
            </PLL>
            </div>
        );
    }
}