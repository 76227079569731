import React from "react";
import { connect } from "react-redux";
import { getSurveyReusltsbyId, getEmailResponses,getCampaignColumnsbyId ,downloadSurveyResultsbyId} from "../../actions";
import { Divider, Button, Empty, Row, Col, Typography, message } from "antd";
import { LeftOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { saveAs } from 'file-saver'
import DashboardLayout from "../../components/Dashboard/dashboardLayout";
import SurveyResult from "../../components/Dashboard/surveyResult";
import "../../App.css";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const DefaultErrorMessage = () => {
  return (
    <div className="errorMessage">
      <span>
        <Title style={{ textalign: "center", paddingTop: "24px" }}>
          {" "}
          SURVEY RESPONSES DOES NOT EXIST.{" "}
        </Title>
        <Title
          level={4}
          style={{ textalign: "center", paddingTop: "24px", color: "#ccc" }}
        >
          {" "}
          if you think this is the isseue then please report it to singualr
          informatics.{" "}
        </Title>
      </span>
    </div>
  );
};
const { Title } = Typography;
class ResultSurveyCampaign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ansewersData: [],
      useEmailResult: [],
      campaignHeaderData:[],
    };
    this.downloadFile = this.downloadFile.bind(this);
  }

  getSurveyData(campaignId) {
    this.props.getCampaignColumnsbyId(campaignId).then((campaignHeader) =>{
      this.props
      .getSurveyReusltsbyId(campaignId)
      .then((campaigndata) => {
        this.props
      .getEmailResponses(campaignId)
      .then((emailData) => {
        this.setState({ useEmailResult: emailData ,ansewersData: campaigndata,campaignHeaderData:campaignHeader});
      })
      .catch((err) => {
        message.warning(
          "Something went wrong,if you think this is an issue please contact your site administrator",
          2
        );
      });
      })
      .catch((err) => {
        message.warning(
          "Something went wrong,if you think this is an issue please contact your site administrator",
          2
        );
      });
    }).catch((err) =>{
      message.warning(
        "Something went wrong,if you think this is an issue please contact your site administrator",
        2
      );
    })
    
  }

  getuserEmailData(campaignId) {
    this.props
      .getEmailResponses(campaignId)
      .then((data) => {
        this.setState({ useEmailResult: data });
      })
      .catch((err) => {
        message.warning(
          "Something went wrong,if you think this is an issue please contact your site administrator",
          2
        );
      });
  }

  componentWillMount() {
    var campaignId = this.props.location.state.id;
    if (campaignId !== undefined) {
      this.getSurveyData(campaignId);
    }
  }

  componentWillReceiveProps(props){
    this.forceUpdate();
  }



  downloadFile() {
    this.props.downloadSurveyResultsbyId(this.props.location.state.id)
      .then((data) => {
        debugger;
        var todayDate = new Date();
        var todayDateForFileName = todayDate.getDate()+"-" +todayDate.getMonth()+"-"+todayDate.getFullYear()+"-"+todayDate.getHours()+"-"+todayDate.getMinutes()+"-"+todayDate.getSeconds();        
        var exportedFilename = todayDateForFileName + ".xlsx"; 
        debugger;
        saveAs(data, exportedFilename);
        message.info( "Downloaded sucessfully", 2); 
      }).catch((err) => {
        message.warning( "Something went wrong,if you think this is an issue please contact your site administrator", 2);
      });
  }
  renderEditor() {
    if (
       this.state.ansewersData.length === 0  &&
       this.state.useEmailResult.length === 0
    ) {
      return (
        <DashboardLayout>
          <Link to="/dashboard">
            <Button
              type="primary"
              style={{ float: "left" }}
              icon={<LeftOutlined />}
            >
              Back
            </Button>
          </Link>
          <br />
          <Row type="flex" style={{ alignItems: "center", paddingTop: "10%" }}>
            <Col span={8} />
            <Col span={8}>
              <Empty />
              <DefaultErrorMessage />
            </Col>
            <Col span={8} />
          </Row>
        </DashboardLayout>
      );
    } else {
      return (
        <div>
          <div>
            <DashboardLayout>
              <Link to="/dashboard">
                <Button
                  type="primary"
                  style={{ float: "left" }}
                  icon={<LeftOutlined />}
                >
                  Back
                </Button>
              </Link>
              <Button
                type="sucess"
                style={{
                  float: "left",
                  marginLeft: "24px",
                  background: "#27AE60",
                  color: "#FFF",
                }}
                icon={<CloudDownloadOutlined />}
                onClick={this.downloadFile}
              >
                Download Campagin Result
              </Button>
              <br />
              <Divider />
              <Title level={3}>{'Survey : ' + this.props.location.state.c_name}</Title>
              {/* <Suspense
                fallback={
                  <div className="example">
                    <Spin indicator={antIcon} />
                  </div>
                }
              ></Suspense> */}
              <SurveyResult
                resultDate={this.state.ansewersData}
                userEmailData={this.state.useEmailResult}
                campaignColHeader={this.state.campaignHeaderData}
              />
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }

  render() {
    return <div>{this.renderEditor()}</div>;
  }
}
function mapStateToProps({ campaign }) {
  return { campaign };
}
export default withRouter(
  connect(mapStateToProps, { getSurveyReusltsbyId, getEmailResponses,getCampaignColumnsbyId ,downloadSurveyResultsbyId})(
    ResultSurveyCampaign
  )
);
