import React from "react";
import { Row } from 'antd';
import { getProjectResponces } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class ProjectList extends React.Component{
    componentDidMount() {
        this.props.getProjectResponces();
      }

render() {
    return (
      <div className="site-card-wrapper container"> 
        <Row>
          {
             this.props.project.map(entery =>
              <div className="col-12 col-lg-4 col-md-6 pt-3" key={entery._id}>
              <div className="card-deck">
                <div className="card">
                <img height="200" alt="example" src={`${entery.image}`} />
                  <div className="card-body">
                    <h6 className="card-title">{entery.name}</h6>
                    <p className="card-text">Area : {entery.area} <br/>
                    Sq. feet : {entery.squareft}</p>
                  </div>
                </div>
              </div>
              </div>
            )
          }
        </Row>
      </div>
    );
  }
}

  function mapStateToProps({ project }) {
    return { project };
  }
  export default withRouter(connect(mapStateToProps,{getProjectResponces} )(ProjectList));