import React from "react";
import { Card } from "antd";
import facebook from "../../resources/facebook.png";
import blogger from "../../resources/blogger.png";

export default class SocialMedia extends React.PureComponent {
  render() {
    return (
      <div className="socialMediaContainer">
        <Card
          style={{
            width: 200,
            position: "absolute",
            height: "0px",
            right: "0%",
          }}
          bordered="false"
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://adhstudio.blogspot.com/"
          >
            <img
              src={blogger}
              className="socialMediaIcon"
              alt="Discoverhomes"
            />
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/A-Discover-Home-2261467450763536/"
          >
            <img
              src={facebook}
              className="socialMediaIcon"
              alt="Discoverhomes"
            />
          </a>
        </Card>
      </div>
    );
  }
}
