import React, { Component } from "react";
import { Card, Typography, Form, Button, Input, Divider, Row, Col,message } from "antd";
import { connect } from "react-redux";
import { loginUser } from "../../actions";
import { withRouter, Redirect } from "react-router-dom";
import "./login.css";
import Header from "../Product/header/Header";
import "../Product/header/header.css";

// const layout = {
//   labelCol: {
//     span: 8,
//   },
//   wrapperCol: {
//     span: 16,
//   },
// };
// const tailLayout = {
//   wrapperCol: {
//     offset: 8,
//     span: 16,
//   },
// };
const { Title } = Typography;
const PHeader = withRouter(props => <Header {...props}/>);

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      redirect: false,
    };
  }
  // componentDidMount() {
  //     // If logged in and user navigates to Login page, should redirect them to dashboard
  //     if (this.props.auth.isAuthenticated) {

  //       //  return(<Redirect to="/dashboard" />);
  //       //this.setState({redirect : true});
  //       // window.location.href = "/dashboard";
  //       // debugger;
  //       // this.props.history.push("/dashboard");
  //     }
  //   }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.name !== undefined && nextProps.errors.response === undefined) {
      this.setState({ redirect: true });
    }else{
      this.setState({ redirect: false });
      message.warn("Invalid Authentication", 1);
    }


    if (nextProps.errors) {
      //   this.setState({
      //     errors: nextProps.errors
      //   });
    }
  }
  onFinish = (value) => {
    const userData = {
      email: value.username,
      password: value.password,
    };
    this.props.loginUser(userData);
  };
  render() {
    if (this.state.redirect) {
      //return (<Redirect to={{ pathname: "/dashboard" }} />);
      return <Redirect to="/dashboard" />;
    }
    return (
      <div>
        <PHeader />
        <Row justify="center" className="login-row">
          <Col>
            <Card style={{ width: 350 }}>
              <Title level={3} style={{ textAlign: "center" }}>
                {" "}
                Singular Informatics
              </Title>

              <Divider />
              <Form name="Login" onFinish={this.onFinish}>
                <Form.Item
                  label="Username"
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password />
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

// LoginPage.propTypes = {
//     loginUser: PropTypes.func.isRequired,
//     auth: PropTypes.object.isRequired,
//     errors: PropTypes.object.isRequired
//   };

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default withRouter(connect(mapStateToProps, { loginUser })(LoginPage));
