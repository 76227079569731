import React from 'react';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import BannerImage from './BannerImage';
import { addUserEmail, getActiveSurvey } from '../../actions';
import { connect } from 'react-redux';

class Banner extends React.PureComponent {
	state = {
		loadSurvey: false,
		email: [],
		campaignId: '',
		campaign: '',
		redirect: false,
		emailId: '',
  };
  constructor(props) {
    super(props);
    this.registerEmail = this.registerEmail.bind(this);
  }
	static propTypes = {
		className: PropTypes.string,
	};
	static defaultProps = {
		className: 'home-banner',
	};
	componentDidMount() {
		this.props.getActiveSurvey().then((response) => {
			if (response.length > 0) {
				this.setState({
					campaignId: response[0]._id,
					campaign: response[0].questions,
				});
			}
			// console.log(response);
		});
	}

	registerEmail = (value) => {

		const email = value.email;

		this.props.addUserEmail(email, this.state.campaignId).then((response) => {
			this.setState({
				email: value.email,
				emailId: response.emailID,
				campaign: this.state.campaign,
				campaignId: this.state.campaignId,
				redirect: true
      		});
    	});
    
		// this.setState({ loadSurvey: false });
	};

	render() {
		const { className } = this.props;
		if (this.state.redirect) {
			return (
				<Redirect
					to={{
						pathname: '/startsurvey',
						state: {
              email: this.state.email,
              emailID: this.state.emailId,
							campaignId: this.state.campaignId,
							campaign: this.state.campaign,
						},
					}}
				/>
			);
    }
    
		return (
			<div className={`home-layout-wrapper ${className}`}>
				<div className="home-layout">
					<QueueAnim className={`${className}-content-wrapper`} delay={300} ease="easeOutQuart">
						<h1 key="h2"> A Discover Home </h1> <p key="p"> Redefine your lifestyle....<br/> <br/>
						Conduct a personalized and confidential <br/> Advanced Survey for Homes!</p>
						<Form 
							layout="vertical"
							onFinish={(values) => { 
								 this.registerEmail(values);
							}}>
							<Form.Item
								name="email"
								rules={[
									{
										type: 'email',
										message: 'The input is not valid E-mail!',
									},
									{
										required: true,
										message: 'Please input your E-mail!',
									},
								]}>
								<Input
									placeholder="Enter email to begin survey"
									style={{
										width: '300px',
									}}
								/>
							</Form.Item>
							<Form.Item name="getStarted">
								<span key="button">
									<Button htmlType="submit" type="primary">
										Choose Your Lifestyle
									</Button>
								</span>
								<span>
								<br/>
								<p key="p">Get free weekly newsletter on latest homes according to your budget.</p>
								</span>
							</Form.Item>
						</Form>
					</QueueAnim>
					<div className={`${className}-image-wrapper`}>
						<BannerImage />
					</div>
				</div>
			</div>
		);
	}
}

// export default withRouter(Banner);
function mapStateToProps({ getActiveSurvey, loadActiveSurvey }) {
	return {
		getActiveSurvey,
		loadActiveSurvey,
	};
}
export default withRouter(
	connect(mapStateToProps, {
		addUserEmail,
		getActiveSurvey,
	})(Banner),
);
