import React from 'react';


export default class EmailView extends React.Component{
    render(){
        return(
            <div>

            </div>
        )
    }
}