import React from "react";
import { Switch, Card, message } from "antd";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { EditOutlined, EllipsisOutlined } from "@ant-design/icons";
import { activateSurveyById, getCampaignCountbyId } from "../../actions";

const { Meta } = Card;

class ExistingCampaign extends React.Component {
  state = {
    loading: false,
    editCampaign: false,
    showDetails: false,
    cantEdit: false,
  };

  onChange = (checked) => {
    this.setState({ loading: !checked });
  };

  onEdit = (checked) => {
    if (this.props.id !== undefined) {
      this.props.getCampaignCountbyId(this.props.id).then((result) => {
        if (result > 0) {
          message.warning(
            "This survey can not be edited. Please create a new one.",
            1
          );
          this.setState({ cantEdit: !checked });
        } else {
          this.setState({ editCampaigns: !checked });
          this.render();
        }
      });
    }
  };

  showResult = (clicked) => {
    this.setState({ showDetails: !clicked });
  };
  activateCampaign(status) {
    this.props.isActivateClicked(this.props.id, status);
  }

  render() {
    const { loading } = this.state;
    const name = this.props.name;
    const description = "";
    const campaignId = this.props.id;
    //const description   = this.props.description;
    if (this.state.editCampaigns) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard/campaign/edit",
            state: { id: campaignId },
          }}
        />
      );
    }

    if (this.state.showDetails) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard/campaign/result",
            state: { id: campaignId, c_name: name },
          }}
        />
      );
    }
    return (
      <div>
        <Card
          style={{ width: 300, marginTop: 16 }}
          loading={loading}
          actions={[
            <EditOutlined
              key="edit"
              onClick={() => {
                this.onEdit();
              }}
            />,
            <EllipsisOutlined
              key="ellipsis"
              onClick={() => {
                this.showResult();
              }}
            />,
            <Switch
              key="activate"
              checked={this.props.isActive}
              onChange={(status) => {
                this.activateCampaign(status);
              }}
            />,
          ]}
        >
          <Meta title={name} description={description} />
        </Card>
      </div>
    );
  }
}

function mapStateToProps({ activateCampaign, campaignCount }) {
  return { activateCampaign, campaignCount };
}
export default withRouter(
  connect(mapStateToProps, { activateSurveyById, getCampaignCountbyId })(
    ExistingCampaign
  )
);
