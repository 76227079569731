import React from "react";
import { connect } from "react-redux";
import { getSurveyQuestionsbyId } from "../../actions";
import { Divider, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import DashboardLayout from "../../components/Dashboard/dashboardLayout";

import SurveyEditor from "../../components/Dashboard/surveyCreator";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
var previosProps;
function Loader() {
  return (
    <DashboardLayout>
      <div>
        <Spin
          indicator={antIcon}
          style={{ textalign: "center", padding: "25% 50%" }}
        />
      </div>
    </DashboardLayout>
  );
}

class EditSurveyCampaign extends React.Component {
  componentDidMount() {
    var campaignId = this.props.location.state.id;
    if (campaignId !== undefined) {
      this.props.getSurveyQuestionsbyId(campaignId);
    }
  }

  renderEditor() {
    if (this.props.campaign.length === 0) {
      return <Loader />;
    } else if (previosProps === this.props.campaign) {
      return <Loader />;
    } else {
      previosProps = this.props.campaign;
      return (
        <div>
          <div>
            <DashboardLayout>
              <Link to="/dashboard">
                <Button
                  type="primary"
                  style={{ float: "left" }}
                  icon={<LeftOutlined />}
                >
                  Back
                </Button>
              </Link>
              <br />
              <Divider />
              {/* <Suspense
                fallback={
                  <div className="example">
                    <Spin indicator={antIcon} />
                  </div>
                }
              > */}
              <SurveyEditor campaign={this.props.campaign} />
              {/* </Suspense> */}
            </DashboardLayout>
          </div>
        </div>
      );
    }
  }

  render() {
    return <div>{this.renderEditor()}</div>;
  }
}
function mapStateToProps({ campaign }) {
  return { campaign };
}
export default withRouter(
  connect(mapStateToProps, { getSurveyQuestionsbyId })(EditSurveyCampaign)
);
