import React from 'react';
import './App.css';
import DHappRouter from './routes/index';

function App() {
  return (
    <div>
      <DHappRouter className="rootCss"/>
    </div>
  );
}

export default App;
