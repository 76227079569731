import React from 'react';
// import SurveyCards from '../../components/Dashboard/surveyCards';
import Header from '../../components/Product/header/Header';
// import Footer from '../../components/Product/ProductFooter';


export default class frmSurveyCards extends React.Component {
    render() {
        return (
            <div>
                <Header />
            </div>
        )
    }
}