import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Card, Row, Typography } from "antd";
import home from "../../resources/home.jpg";
import building from "../../resources/building.jpg";
import budget from "../../resources/Budget.jpg";
import SinkingFund from "../../resources/SinkingFund.jpg";
import ask from "../../resources/question.jpg";
import "./index.css";

const { Text } = Typography;

class AboutUs extends React.Component {
  render() {
    return (
      <Card bordered={false}>
        <Text strong={false} >
          <div className="text-center">
            Our dynamic team provides, Customized solutions for individual and
            community owned real estate project and services for development and
            redevelopment of the land.
            </div>
        </Text>
        <br />
        <br />
        <h2 className="text-center">Services</h2>
        <br />
        <div className="site-card-wrapper container">
          <Row>
            <div className="col-12 col-lg-4 col-md-6 pt-3">
            <Link to="/viewContact">
              <div className="card-deck">
                <div className="card">
                  <img src={home} className="card-img-top" alt="..." height="200px"/>
                  <div className="card-body">
                    <h6 className="card-title">Find Homes</h6>
                  </div>
                </div>
              </div>
              </Link>
              </div>
              <div className="col-12 col-lg-4 col-md-6 pt-3">
            <Link to="/viewContact">
              <div className="card-deck">
                <div className="card">
                  <img src={building} className="card-img-top" alt="..." height="200px"/>
                  <div className="card-body">
                    <h6 className="card-title">FHR : Building (Homes)</h6>
                  </div>
                </div>
              </div>
              </Link>
              </div>
              <div className="col-12 col-lg-4 col-md-6 pt-3">
            <Link to="/viewContact">
              <div className="card-deck">
                <div className="card">
                  <img src={budget} className="card-img-top" alt="..." height="200px"/>
                  <div className="card-body">
                    <h6 className="card-title">PMC: Budget and Audit (technical)</h6>
                  </div>
                </div>
              </div>
              </Link>
              </div>
              <div className="col-12 col-lg-4 col-md-6 pt-3">
            <Link to="/viewContact">
              <div className="card-deck">
                <div className="card">
                  <img src={SinkingFund} className="card-img-top" alt="..." height="200px"/>
                  <div className="card-body">
                    <h6 className="card-title">PMS: Sinking Fund</h6>
                  </div>
                </div>
              </div>
              </Link>
              </div>
            <div className="col-12 col-lg-4 col-md-6 pt-3">
            <Link to="/viewContact">
              <div className="card-deck">
                <div className="card">
                  <img src={ask} className="card-img-top" alt="..." height="200px"/>
                  <div className="card-body">
                    <h6 className="card-title">Get Quote</h6>
                  </div>
                </div>
              </div>
              </Link>
              </div>
          </Row>
        </div>
      </Card>
    );
  }
}

export default withRouter(AboutUs);
