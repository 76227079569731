import React from "react";
import { Layout } from 'antd';
import {withRouter} from 'react-router-dom';
import DashboardHeader from "./dashboardHeader";
import "./dashboardLayout.css";

const {  Header, Content, Footer } = Layout;
const DHeader = withRouter(props => <DashboardHeader {...props}/>);
export default class DashboardLayout extends React.Component {
  // state = {
  //   collapsed: false,
  // };

  // toggle = () => {
  //   this.setState({
  //     collapsed: !this.state.collapsed,
  //   });
  // };

  render() {
    return (
      <Layout style={{minHeight:'100vh'}}>        
        <DHeader />
        {/* <Sider trigger={null}  style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}> */}
          {/* <div className="logo" /> */}
          {/* <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
            <Menu.Item key="1" icon={<UserOutlined />}>
              Dashboard
            </Menu.Item>
            <Menu.Item key="2" icon={<FormOutlined />}>
              Contactus Responses
            </Menu.Item>
            <Menu.Item key="3" icon={<LogoutOutlined />}>
              Logout
            </Menu.Item>
          </Menu>
        </Sider> */}
        <Layout className="site-layout" style={{ marginLeft: 200 }}>
<Header className="site-layout-background" style={{ padding: 0 }}>
            {/* {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
              className: 'trigger',
              onClick: this.toggle,
            })} */}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              overflow: 'auto',
              margin: '24px 16px',
              padding: 24,
              minHeight: "auto",
            }}
          >
           {this.props.children}
          </Content>
          <Footer style={{ textAlign: 'center' }}>©2020 Singular Informatics </Footer>
        </Layout>
       
      </Layout>
    );
  }
}