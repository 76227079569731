import React from 'react';
import SurveyCreator from '../../components/Dashboard/surveyCreator';
import DL from '../../components/Dashboard/dashboardLayout';
// import '../../App.css';
// import '~antd/dist/antd.css';

export default class frmSurveyCreator extends React.Component {
    
    render() {
        return (
            <div>
                <DL>
                <SurveyCreator />
                </DL>
            </div>
        )
    }
}