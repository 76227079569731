import { SUBMIT_PRODUCT,DELETE_PRODUCT,FETCH_PRODUCT,UPDATE_PRODUCT } from '../actions/types';

export default function (state = [], action) {
    switch (action.type) {
        case SUBMIT_PRODUCT:
            return action.payload;
        case FETCH_PRODUCT:
            return action.payload;
        case DELETE_PRODUCT:
            return action.payload;
        case UPDATE_PRODUCT:
            return action.payload;
        default:
            return state
    }
}