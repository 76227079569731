import React from "react";
import { Row } from 'antd';
import { getStudioResponces } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";


class StudioList extends React.Component{
    componentDidMount() {
        this.props.getStudioResponces();
      }

render() {
    return (
      <div className="site-card-wrapper container"> 
        <Row>
          {
             this.props.studio.map(entery =>
              <div className="col-12 col-lg-4 col-md-6 pt-3" key={entery._id}>
              <div className="card-deck">
                <div className="card">
                <img height="200" alt="example" src={`${entery.image}`} />
                  <div className="card-body">
                    <h6 className="card-title">{entery.title}</h6>
                    <p className="card-text">Address : {entery.address} <br/>
                    Map : <a href={entery.gmaplink} target="_blank" rel="noopener noreferrer">Google map</a></p>
                  </div>
                </div>
              </div>
              </div>
            )
          }
        </Row>
      </div>
    );
  }
}

  function mapStateToProps({ studio }) {
    return { studio };
  }
  export default withRouter(connect(mapStateToProps,{getStudioResponces} )(StudioList));