import axios from "axios";
import Promise from "bluebird";
import {
  FETCH_SURVEYS,
  FETCH_SURVEY_BY_ID,
  ACTIVATE_SURVEY_BY_ID,
  LOAD_ACTIVE_SURVEY,
  SUBMIT_SURVEY_RESULT,
  SUBMIT_USER_EMAIL,
  SUBMIT_CONTACTUS,
  FETCH_SURVEY_RESULT_BY_ID,
  DOWNLOAD_SURVEY_RESULT_BY_ID,
  FETCH_SURVEYCOUNT_BY_ID,
  FETCH_CONTACTUS_RESPONCES,
  FETCH_EMAIL_RESPONSE_BY_ID,
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  FETCH_CAMPAIGN_COLUMN,
  SUBMIT_STUDIO,
  FETCH_STUDIO,
  DELETE_STUDIO,
  UPDATE_STUDIO,
  SUBMIT_PROJECT,
  FETCH_PROJECT,
  DELETE_PROJECT,
  UPDATE_PROJECT,
  SUBMIT_PRODUCT,
  FETCH_PRODUCT,
  DELETE_PRODUCT,
  UPDATE_PRODUCT
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";


export const fetchSurveys = () => async (dispatch) => {
  return axios.get("/api/campagin/all").then(({
    data
  }) => {
    dispatch({
      type: FETCH_SURVEYS,
      payload: data
    });
  });
};

export const getSurveyQuestionsbyId = (id) => async (dispatch) => {
  return axios.get(`/api/campagin/edit/${id}`).then(({
    data
  }) => {
    dispatch({
      type: FETCH_SURVEY_BY_ID,
      payload: data
    });
  });
};

export const activateSurveyById = (id, isActivated) => async (dispatch) => {
  return axios
    .post(`/api/campaign/activate/${id}`, {
      isActivated: isActivated
    })
    .then(({
      data
    }) => {
      dispatch({
        type: ACTIVATE_SURVEY_BY_ID,
        payload: data
      });
    });
};

export const getActiveSurvey = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/campagin/active`)
      .then(({
        data
      }) => {
        dispatch({
          type: LOAD_ACTIVE_SURVEY,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: LOAD_ACTIVE_SURVEY,
          payload: error
        });
        reject(error);
      });
  });
};

//TODO:This is a partial promise written, need to add both resolve and reject block
export const addSurveyResponse = (id, data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/campaign/response/${id}`, {
        response: data
      })
      .then(({
        data
      }) => {
        dispatch({
          type: SUBMIT_SURVEY_RESULT,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_SURVEY_RESULT,
          payload: error
        });
        reject(error);
      });
  });
};

export const addUserEmail = (emailId, campaignId) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/email/add`, {
        email: emailId,
        campaignid: campaignId
      })
      .then(({
        data
      }) => {
        dispatch({
          type: SUBMIT_USER_EMAIL,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: SUBMIT_USER_EMAIL,
          payload: error
        });
        reject(error);
      });
  });
};

export const addContctus = (data) => async (dispatch) => {
  return axios
    .post(`/api/contactus/add`, {
      response: data
    })
    .then(({
      data
    }) => {
      dispatch({
        type: SUBMIT_CONTACTUS,
        payload: data
      });
    });
};

export const getSurveyReusltsbyId = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/campaign/answers/${id}`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_SURVEY_RESULT_BY_ID,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SURVEY_RESULT_BY_ID,
          payload: error
        });
        reject(error);
      });
  });
};

export const downloadSurveyResultsbyId = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/campaign/download/${id}`, {
        responseType: 'blob',
    })
      .then(({
        data
      }) => {
        dispatch({
          type: DOWNLOAD_SURVEY_RESULT_BY_ID,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: DOWNLOAD_SURVEY_RESULT_BY_ID,
          payload: error
        });
        reject(error);
      });
  });
};

export const getCampaignCountbyId = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/campaign/recordcnt/${id}`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_SURVEYCOUNT_BY_ID,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_SURVEYCOUNT_BY_ID,
          payload: error
        });
        reject(error);
      });
  });
};

export const getContactusResponces = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/contactus/get`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_CONTACTUS_RESPONCES,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CONTACTUS_RESPONCES,
          payload: error
        });
        reject(error);
      });
  });
};

export const getEmailResponses = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/email/get/${id}`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_EMAIL_RESPONSE_BY_ID,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_EMAIL_RESPONSE_BY_ID,
          payload: error
        });
        reject(error);
      });
  });
};
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/login", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const {
        token
      } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      localStorage.removeItem("jwtToken");
      dispatch({
        type: GET_ERRORS,
        payload: err,
      });

    });
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

//get campaign columns
export const getCampaignColumnsbyId = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/campaign/colheader/${id}`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_CAMPAIGN_COLUMN,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CAMPAIGN_COLUMN,
          payload: error
        });
        reject(error);
      });
  });
};

//Add Studio
export const addStudio = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/studio/add`, data).then(({
      data
    }) => {
      dispatch({
        type: SUBMIT_STUDIO,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: SUBMIT_STUDIO,
          payload: error
        });
        reject(error);
      });
  });
};

//Update Studio
export const updateStudio = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/studio/update`, data).then(({
      data
    }) => {
      dispatch({
        type: UPDATE_STUDIO,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: UPDATE_STUDIO,
          payload: error
        });
        reject(error);
      });
  });
};

//Get Studio
export const getStudioResponces = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.get(`/api/studio/get`).then(({
      data
    }) => {
      dispatch({
        type: FETCH_STUDIO,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: FETCH_STUDIO,
          payload: error
        });
        reject(error);
      });
  });
};

//Delete Studio
export const deleteStudiobyId = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/studio/delete/${id}`,)
      .then(({
        data
      }) => {
        dispatch({
          type: DELETE_STUDIO,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: DELETE_STUDIO,
          payload: error
        });
        reject(error);
      });
  });
};


//Add Project
export const addProject = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/project/add`, data ).then(({
      data
    }) => {
      dispatch({
        type: SUBMIT_PROJECT,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: SUBMIT_PROJECT,
          payload: error
        });
        reject(error);
      });
  });

};

//Get Project
export const getProjectResponces = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/project/get`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_PROJECT,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PROJECT,
          payload: error
        });
        reject(error);
      });
  });
};

//Delete Project
export const deleteProject = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/project/delete/${id}`).then(({
      data
    }) => {
      dispatch({
        type: DELETE_PROJECT,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: DELETE_PROJECT,
          payload: error
        });
        reject(error);
      });
  });
};

//Update Project
export const updateProject = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/project/update`, data ).then(({
      data
    }) => {
      dispatch({
        type: UPDATE_PROJECT,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: UPDATE_PROJECT,
          payload: error
        });
        reject(error);
      });
  });
};


//Add Product
export const addProduct = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/product/add`, data ).then(({
      data
    }) => {
      dispatch({
        type: SUBMIT_PRODUCT,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: SUBMIT_PRODUCT,
          payload: error
        });
        reject(error);
      });
  });

};

//Get Product
export const getProductResponces = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`/api/product/get`)
      .then(({
        data
      }) => {
        dispatch({
          type: FETCH_PRODUCT,
          payload: data
        });
        resolve(data);
      })
      .catch((error) => {
        dispatch({
          type: FETCH_PRODUCT,
          payload: error
        });
        reject(error);
      });
  });
};

//Delete Product
export const deleteProduct = (id) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.delete(`/api/product/delete/${id}`).then(({
      data
    }) => {
      dispatch({
        type: DELETE_PRODUCT,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: DELETE_PRODUCT,
          payload: error
        });
        reject(error);
      });
  });
};

//Update Product
export const updateProduct = (data) => async (dispatch) => {
  return new Promise((resolve, reject) => {
    axios.post(`/api/product/update`, data ).then(({
      data
    }) => {
      dispatch({
        type: UPDATE_PRODUCT,
        payload: data
      });
      resolve(data);
    })
      .catch((error) => {
        dispatch({
          type: UPDATE_PRODUCT,
          payload: error
        });
        reject(error);
      });
  });
};