import React from "react";
import { PageHeader,Form, Input, InputNumber, Button ,DatePicker,Table,Row,Col,Space,message,Popconfirm} from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Resizer from 'react-image-file-resizer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { addProject,getProjectResponces,deleteProject,updateProject } from "../../actions";
import moment from "moment";

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  class Projects extends React.Component{

    formRef = React.createRef();

    state = {
      columns :[],
      projectData: [],
      selectedFile: null,
      fileName:''
    };

    resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
    });

    onChange = async (e) => {
      this.setState({selectedFile:null,fileName:''});
      const param = e.target.files[0];
      const image = await this.resizeFile(param);
      this.setState({selectedFile:image,fileName :param.name });
    }
    
    componentDidMount(){
      this.setState({columns : [
        {
          title: "Sr No",
          dataIndex: "srno",
          key: 'srno',
          width: 50
        },
        {
          title: "Name",
          dataIndex: "name",
          key: 'name',
          width: 120,
        },
        {
          title: "Area",
          dataIndex: "area",
          key: 'area',
          width: 140,
        },
        {
          title: "Sq. Feet",
          dataIndex: "squareft",
          key: 'squareft',
          width: 120,
        },
        {
          title: "DOC",
          dataIndex: "dateofcomplition",
          key: 'dateofcomplition',
          width: 120,
        },
        {
          title: "SOU",
          dataIndex: "sizeofunit",
          key: 'sizeofunit',
          width: 100,
        },
        {
          title: "Link",
          dataIndex: "gmaplink",
          key: 'name',
          width: 130,
        },
        {
          title: 'Action',
          key: 'action',
          // dataIndex: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Button type="link" size={"small"} onClick={() => this.editProjectInfo({record})}>Edit</Button>
              <Popconfirm title="Are you sure to delete this Record？" placement="top" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => this.deleteProjectInfo({record})}><Button type="link" size={"small"} >Delete</Button></Popconfirm>
            </Space>
          ),
          width: 70
          }
      ]})
      this.getProjectInfo();
    }

    getProjectInfo = () => {
      this.props.getProjectResponces().then((result) => {
        if (result.length > 0) {
          var cnt = 1;
          var gridData = [];
          result.forEach(element => {
            gridData.push({
              srno : cnt++,
              key : cnt,
              projectId : element._id,
              name : element.name,
              area :element.area,
              squareft :element.squareft,
              dateofcomplition : new Date(element.dateofcomplition).toLocaleString(), 
              sizeofunit :element.sizeofunit,
              gmaplink :element.gmaplink,
              file : element.image,
              filename : element.filename,
            })
          });
          this.setState({ projectData: gridData });
          this.render();
        } 
        else
        {
          this.setState({ projectData: null });
          this.render();
        }
      });;
    }

    addProjectInfo = (values) => {
      if (this.state.selectedFile === undefined || this.state.selectedFile === null) {
        values.file = ''
      }
      else
      {
        values.file = this.state.selectedFile;
      }
      if (this.state.fileName === undefined || this.state.fileName === null) {
        values.fileName = ''
      }
      else
      {
        values.fileName = this.state.fileName;
      }

      if (values.gmaplink === undefined) {
        values.gmaplink = ''
      }
      
      const formData = new FormData();
      formData.append('file',this.state.selectedFile);
      formData.append('name',values.name);
      formData.append('area',values.area);
      formData.append('squareft',values.squareft);
      formData.append('filename',this.state.fileName);
      formData.append('dateofcomplition',values.dateofcomplition);
      formData.append('sizeofunit',values.sizeofunit);
      formData.append('gmaplink',values.gmaplink);

      if (values.projectId !== undefined && values.projectId !== null) {
        formData.append('projectId',values.projectId);
        this.props.updateProject(formData).then((result) => {
          if (result.message !== undefined) {
            message.info(this.props.projects.project.message, 1);
          } 
        });
      }
      else{
        this.props.addProject(formData).then((result) => {
          if (result.message !== undefined) {
            message.info(this.props.projects.project.message, 1);
          } 
        });
      }
      this.formRef.current.resetFields();
      this.setState({selectedFile:null,fileName:''});
      this.getProjectInfo();
    }


    editProjectInfo = (values) =>{
      this.formRef.current.setFieldsValue({
        projectId : values.record.projectId,
        name: values.record.name,
        area: values.record.area,
        squareft: values.record.squareft,
        dateofcomplition: moment(values.record.dateofcomplition),
        sizeofunit: values.record.sizeofunit,
        gmaplink: values.record.gmaplink,
        file :values.record.file
      });
      if (values.record.file === "null") {
        this.setState({selectedFile:null,fileName:''});
      }
      else{
        this.setState({selectedFile:values.record.file,fileName:values.record.filename});
      }
      
    }

    deleteProjectInfo = (value) => {
      if (value.record.projectId !== undefined) {
        this.props.deleteProject(value.record.projectId).then((result) =>{
          if (result.message !== undefined) {
            message.info(this.props.projects.project.message, 1);
            this.getProjectInfo();
          } 
        })
      }else{
        message.warning("project id not found", 1);
      }
    }

    resetFields = () => {
      this.formRef.current.resetFields();
      this.setState({selectedFile:null});
    }

      render(){
      return(
          <div>
              <PageHeader title="Projects"/>
                <Form {...layout} ref={this.formRef} name="nest-messages"  onFinish={(values) => { this.addProjectInfo(values);}}>
                <Row justify="start">
                <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                <Form.Item name="projectId" label="Project Id" hidden={true}>
                  <Input />
                </Form.Item>
                  <Form.Item name="name" label="Name" rules={[
                      {
                        required: true,
                        message: 'Please select project name!'
                      },
                      {
                        pattern: /^.{1,20}$/,
                        message: "Value should be less than 20 character",
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="area" label="Area" rules={[
                      {
                        required: true,
                        message: 'Please select area!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="squareft" label="Square feet" rules={[
                      {
                        type: 'number',
                        // min: 0,
                        // max: 99,
                        required: true,
                        message: 'Please select sq. Feet!',
                      },
                    ]}
                  >
                    <InputNumber  style={{width: "50%" }}/>
                  </Form.Item>
                  <Form.Item  name="dateofcomplition" label="Date of Complition" rules={ [
                    {
                      type: 'object',
                      required: true,
                      message: 'Please select time!',
                    },
                  ]}>
                    <DatePicker format="DD-MM-YYYY" style={{width: "50%" }}/>
                  </Form.Item>
                  </Col>
                  <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <Form.Item name="sizeofunit" label="Size of Unit" rules={[
                    {
                      required: true,
                      message: 'Please select size of unit!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="gmaplink" label="Google Link">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Logo" >
                  <Input type="file" name="file" onChange={this.onChange} accept="image/*" value=""/>
                  <input type="hidden" name="filename" />
                  <br/>
                  {this.state.selectedFile ? <img alt="" src={`${this.state.selectedFile}`} height="100px" width="150px"/> : ''}
                 </Form.Item>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                  <Button type="ghost" htmlType="reset" onClick={() => this.resetFields()} >
                      Reset
                    </Button>  
                  <Button type="primary" htmlType="submit" style={{marginRight:"10px"}}>
                      Submit
                    </Button>
                  </Form.Item>
                  </Col>
                  </Row>
                </Form>
            <PageHeader title="Project Details"/>
              <Table  bordered dataSource={this.state.projectData} 
                      columns={this.state.columns} pagination={{ position: [ "bottomRight"] }} scroll={{  y: 300 }}/>
          </div>
        )
      }
  }

  
function mapStateToProps(projects) {
    return { projects };
  }
  
  export default withRouter(connect(mapStateToProps, {  addProject,getProjectResponces,deleteProject,updateProject })(Projects));
  