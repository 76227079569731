import React from "react";
import { Layout, Menu,Typography } from "antd";
import { Link } from "react-router-dom";
import {
  UserOutlined,
  FormOutlined,
  LogoutOutlined,
  HomeOutlined,
  ApartmentOutlined,
  SecurityScanOutlined,
  InsertRowBelowOutlined
} from "@ant-design/icons";
import "./dashboardLayout.css";

const { Sider } = Layout;
const { Text, Title } = Typography;
class DashboardHeader extends React.Component {
  handleSelectIndex = (path) => {
    var routes = [
      " ",
      "/dashboard",
      "/dashboard/project",
      "/dashboard/decor",
      "/dashboard/studio",
      "/dashboard/fetch/contactus",
      "/",
      "/logout",
    ];
    var selectedIndex = "";
    if (path.pathname === "/dashboard")
      selectedIndex = `['` + routes.indexOf("/dashboard") + `']`;
    else if (path.pathname === "/dashboard/project")
      selectedIndex = `['` + routes.indexOf("/dashboard/project") + `']`;
    else if (path.pathname === "/dashboard/decor")
      selectedIndex = `['` + routes.indexOf("/dashboard/decor") + `']`;
    else if (path.pathname === "/dashboard/studio")
        selectedIndex = `['` + routes.indexOf("/dashboard/studio") + `']`;
    else if (path.pathname === "/dashboard/fetch/contactus")
      selectedIndex =
        `['` + routes.indexOf("/dashboard/fetch/contactus") + `']`;
    else if (path.pathname === "/logout")
      selectedIndex = `['` + routes.indexOf("/logout") + `']`;
    else if (path.pathname === "/")
      selectedIndex = `['` + routes.indexOf("/") + `']`;

    return selectedIndex;
  };

  render() {
    const pathName = this.props.location;
    return (
      <Sider
        trigger={null}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <Title style={{paddingLeft:"1em",paddingTop:"1em"}} level={3}><Text style={{color: 'white'}}>Singualr Informatics</Text></Title>
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={this.handleSelectIndex(pathName)}
        >
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link
              to="/dashboard"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              Dashboard{" "}
            </Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<ApartmentOutlined />}>
            <Link
              to="/dashboard/project"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Projects
            </Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<InsertRowBelowOutlined />}>
            <Link
              to="/dashboard/decor"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Decor
            </Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<SecurityScanOutlined  />}>
            <Link
              to="/dashboard/studio"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Studio
            </Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<FormOutlined />}>
            <Link
              to="/dashboard/fetch/contactus"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Contactus Responses
            </Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<HomeOutlined />}>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              Product Home
            </Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<LogoutOutlined />}>
            <Link
              to="/logout"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Logout
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    );
  }
}
export default DashboardHeader;
