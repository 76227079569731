import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
// import { Provider } from 'react-redux';
// import { createStore, applyMiddleware } from 'redux';
// import reduxThunk from 'redux-thunk';
// import reducers from './reducers';

// const store = createStore(reducers,{}, applyMiddleware(reduxThunk));
// ReactDOM.render(
// <Provider store={store}>
// <App />
// </Provider>
//  , document.getElementById('root')
// );

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();
    