import React from "react";
import { Row, Col, message } from 'antd';
import { connect } from 'react-redux';
import { fetchSurveys, activateSurveyById } from '../../actions';
import SingleCampaign from "../../components/Dashboard/campaignCard";
import { withRouter } from "react-router-dom";

class CampaignList extends React.Component {
  constructor(props) {
    super(props)
    this.activatedClicked = this.activatedClicked.bind(this)
}
state = {
isActivated : false
}
  componentDidMount() {
    this.props.fetchSurveys();
  }
  activatedClicked(id, status){  
    if(status)  
    {
      this.props.activateSurveyById(id);       
      message.info(`Activated successfully!`,1);    
      
    }
    else
    {
      message.info(`Already activated!`,1);    
    }
    setTimeout(()=> {       this.props.fetchSurveys()   },100);

      this.forceUpdate();

  
    
    
  }
  render() {

    return (
      <div> 
        <Row gutter={[16, 16]}>
          {
             this.props.surveys.map(entery =>
              <Col key={entery._id}>
                <SingleCampaign name={entery.name} id={entery._id} isActive={entery.isActive} isActivateClicked={this.activatedClicked}/>
              </Col>
            )
          }
        </Row>
      </div>
    );
  }
}


function mapStateToProps({ surveys, activateCampaign }) {
  return { surveys, activateCampaign };
}
export default withRouter(connect(mapStateToProps, { fetchSurveys, activateSurveyById })(CampaignList));