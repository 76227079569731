import React from 'react';
import 'antd/dist/antd.css';
import './ProductFooter.css';



function ProductLandingFooter() {
  return (
    <div className="footer">
        Copyright ©2018-2021. All Rights Reserved for www.discoverhomes.co.in. Website Design and Developed by <a href="http://singularinformatics.com" target="blank">Singular Informatics</a>. 
    </div>
  );
}

export default ProductLandingFooter;
