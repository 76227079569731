import React from 'react';
import Studio from '../../components/Studio/index';
import DL from '../../components/Dashboard/dashboardLayout';
import '../../App.css';
import 'antd/dist/antd.css';
export default class frmStudio extends React.Component 
{
    render()
    {
        return(
            <div>
            <DL>
            <Studio />
            </DL>
            </div>
        );
    }
}