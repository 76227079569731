import React, { Component } from "react";
import { Route, BrowserRouter,Switch } from "react-router-dom";
//import Auth from '../services/Authencation';
import ProductLandingPage from "../container/productLanding/index";
import ContactUs from "../container/frmContactus/index";
import Project from "../container/frmProjects/index";
import ViewProjects from "../container/frmProjects/viewProjects";
import Product from "../container/frmProducts/index";
import ViewProducts from "../container/frmProducts/viewProducts";
import Studio from "../container/frmStudio/index";
import ViewStudio from "../container/frmStudio/viewStudio";
import DHome from "../container/Dashboard/dbHome";
import SurveyCreator from "../container/Dashboard/surveyCreator";
import ContactUsView from "../container/Dashboard/contactusResponse";
import EmailView from "../container/Dashboard/emailview";
import ActiveSurveyResult from "../container/result/index";
import SurveyCards from "../container/Dashboard/surveryCards";
import Login from "../container/frmLogin/index";
import Logout from "../container/Dashboard/logout";
import StartSurvey from "../container/activeSurvey/index";
import EditSurveyCampaign from "../container/Dashboard/editSurveyCampaign";
import ResultSurveyCampaign from "../container/Dashboard/resultCampaign";
import AboutUs from "../container/frmAboutus/index";
import PNF from "../container/404/404";
import PrivateRoute from "./PrivateRoute";
import { Provider } from "react-redux";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "../utils/setAuthToken";
import { setCurrentUser, logoutUser } from "../actions/index";
import "antd/dist/antd.css";
import "../App.css";
// import { LoadingOutlined } from "@ant-design/icons";
// import { Spin } from "antd";

import NewCampaign from "../container/Dashboard/newSurveyCampaign";
// import { Switch } from "antd";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    //window.location.href = "./login";
  }
}

class DHappRouter extends Component {
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ProductLandingPage} />
          <Route exact path="/viewContact" component={ContactUs} />
          <Route exact path="/viewAboutus" component={AboutUs} />

          <Route exact path="/viewProjects" component={ViewProjects} />
          <Route exact path="/viewProducts" component={ViewProducts} />
          <Route exact path="/viewStudio" component={ViewStudio} />

          <Route exact path="/login" component={Login} />
          <Route exact path="/startsurvey" component={StartSurvey} />
          <Route exact path="/result" component={ActiveSurveyResult} />
          <Route exact path="/logout" component={Logout} />

          <PrivateRoute exact path="/dashboard" component={DHome} />
          <PrivateRoute
            exact
            path="/dashboard/creator"
            component={SurveyCreator}
          />
          <PrivateRoute
            exact
            path="/dashboard/conatctusview"
            component={ContactUsView}
          />
          <PrivateRoute
            exact
            path="/dashboard/emailview"
            component={EmailView}
          />
          <PrivateRoute
            exact
            path="/dashboard/surveycards"
            component={SurveyCards}
          />
          <PrivateRoute
            exact
            path="/dashboard/campaign/edit"
            component={EditSurveyCampaign}
          />
          <PrivateRoute
            exact
            path="/dashboard/campaign/result"
            component={ResultSurveyCampaign}
          />
          <PrivateRoute
            exact
            path="/dashboard/fetch/contactus"
            component={ContactUsView}
          />
          <PrivateRoute
            exact
            path="/dashboard/project"
            component={Project}
          />
          <PrivateRoute
            exact
            path="/dashboard/decor"
            component={Product}
          />
          <PrivateRoute
          exact
          path="/dashboard/studio"
          component={Studio}
        />

          {/* <Suspense
            fallback={
              <div className="example">
                {" "}
                <Spin indicator={antIcon} />{" "}
              </div>
            }
          > */}
          <PrivateRoute
            exact
            path="/dashboard/campaign/new"
            component={NewCampaign}
            // render={() => (
            //   <>
            //     {" "}
            //     <NewCampaign />{" "}
            //   </>
            // )}
          />
          {/* </Suspense> */}
          <Route component={PNF}/>
          </Switch>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default DHappRouter;
