import React, { Component } from "react";
import * as SurveyJSCreator from "survey-creator";
import * as Survey from "survey-react";
import axios from "axios";
import { message } from "antd";
import { withRouter, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "survey-creator/survey-creator.css";
import "survey-creator/survey-creator.css";
import "jquery-ui/themes/base/all.css";
import "nouislider/distribute/nouislider.css";
import "select2/dist/css/select2.css";
import "bootstrap-slider/dist/css/bootstrap-slider.css";
import "survey-knockout/survey.css";
import "jquery-bar-rating/dist/themes/css-stars.css";
import "./surveyCreator.css";
import $ from "jquery";
// import "jquery-ui/ui/widgets/datepicker.js";
// import "select2/dist/js/select2.js";
// import "jquery-bar-rating";

import * as SurveyCore from "survey-core";
import * as widgets from "surveyjs-widgets";

import "pretty-checkbox/dist/pretty-checkbox.css";
import "icheck/skins/square/blue.css";
// import { object } from "prop-types";
window["$"] = window["jQuery"] = $;
//require("icheck");

SurveyJSCreator.StylesManager.applyTheme("stone");

//widgets.icheck(Survey, $);
widgets.prettycheckbox(Survey);
widgets.select2(Survey, $);
widgets.inputmask(Survey);
widgets.jquerybarrating(Survey, $);
widgets.jqueryuidatepicker(Survey, $);
widgets.nouislider(Survey);
widgets.select2tagbox(Survey, $);
//widgets.signaturepad(Survey);
widgets.sortablejs(Survey);
widgets.ckeditor(Survey);
widgets.autocomplete(Survey, $);
widgets.bootstrapslider(Survey);

//widgets.icheck(SurveyCore, $);
widgets.prettycheckbox(SurveyCore);
widgets.select2(SurveyCore, $);
widgets.inputmask(SurveyCore);
widgets.jquerybarrating(SurveyCore, $);
widgets.jqueryuidatepicker(SurveyCore, $);
widgets.nouislider(SurveyCore);
widgets.select2tagbox(SurveyCore, $);
//widgets.signaturepad(SurveyCore);
widgets.sortablejs(SurveyCore);
widgets.ckeditor(SurveyCore);
widgets.autocomplete(SurveyCore, $);
widgets.bootstrapslider(SurveyCore);

var mainColor = "#001529";
var mainHoverColor = "#1890ff";
var textColor = "#222";
var headerColor = "#001529";
var headerBackgroundColor = "#4a4a4a";
var bodyContainerBackgroundColor = "#f8f8f8";

var defaultThemeColorsSurvey = Survey.StylesManager.ThemeColors["default"];
defaultThemeColorsSurvey["$main-color"] = mainColor;
defaultThemeColorsSurvey["$main-hover-color"] = mainHoverColor;
defaultThemeColorsSurvey["$text-color"] = textColor;
defaultThemeColorsSurvey["$header-color"] = headerColor;
defaultThemeColorsSurvey["$header-background-color"] = headerBackgroundColor;
defaultThemeColorsSurvey[
  "$body-container-background-color"
] = bodyContainerBackgroundColor;

var defaultThemeColorsEditor =
  SurveyJSCreator.StylesManager.ThemeColors["default"];
defaultThemeColorsEditor["$primary-color"] = mainColor;
defaultThemeColorsEditor["$secondary-color"] = mainColor;
defaultThemeColorsEditor["$primary-hover-color"] = mainHoverColor;
defaultThemeColorsEditor["$primary-text-color"] = textColor;
defaultThemeColorsEditor["$selection-border-color"] = mainColor;

class SurCreator extends Component {
  creator;
  state = { IsRedirect: false };
  componentDidMount() {
    let options = {
      showEmbededSurveyTab: false,
      showToolbox: "right",
      showPropertyGrid: "right",
    };
    Survey.StylesManager.applyTheme();
    SurveyJSCreator.StylesManager.applyTheme();
    this.surveyCreator = new SurveyJSCreator.SurveyCreator(
      "surveyCreatorContainer",
      options
    );
    this.surveyCreator.saveSurveyFunc = this.saveMySurvey;

    if (this.props.campaign !== undefined) {
      if (this.props.campaign.questions !== undefined) {
        this.surveyCreator.text = JSON.parse(this.props.campaign.questions);
      }
    }
  }

  render() {
    if (this.state.IsRedirect) {
      return <Redirect to="/dashboard" />;
    } else {
      return <div id="surveyCreatorContainer" />;
    }
  }

  saveMySurvey = () => {
    let parsedCampaign = JSON.parse(this.surveyCreator.text);

    if (parsedCampaign.title === undefined) {
      message.warning("Please provide campaign title", 1);
    } else if (
      parsedCampaign.pages.length >= 1 &&
      parsedCampaign.pages[0].elements === undefined
    ) {
      message.warning("Please add questions to the campaign", 1);
    } else if (this.props.campaign === undefined) {
      let dynamicCol = "";
      let colArray = JSON.parse(this.surveyCreator.text);
      for (let i = 0; i < colArray.pages.length; i++) {
        if (colArray.pages[i].elements !== undefined && colArray.pages[i].elements.length > 0) {
            for (let j = 0; j < colArray.pages[i].elements.length; j++) {
              if (dynamicCol !== "") dynamicCol += ",";
              dynamicCol += colArray.pages[i].elements[j].name;
            }
        }
      }
      axios
        .post(`/api/campaign/add`, {
          name: parsedCampaign.title,
          questions: JSON.stringify(this.surveyCreator.text),
          questioncolumns:dynamicCol
        })
        .then((res) => {
          if (res.status === 201) {
            this.setState({ IsRedirect: true });
            message.success("Campaign created successfully.", 1);
          }
        })
        .catch((err) => {
          message.error("Campaign creation failed.", 2);
        });
    } else {
      let dynamicCol = "";
      let colArray = JSON.parse(this.surveyCreator.text);
      for (let i = 0; i < colArray.pages.length; i++) {
        if (colArray.pages[i].elements !== undefined && colArray.pages[i].elements.length > 0) {
            for (let j = 0; j < colArray.pages[i].elements.length; j++) {
              if (dynamicCol !== "") dynamicCol += ",";
              dynamicCol += colArray.pages[i].elements[j].name;
            }
        }
      }
      this.setState({ IsRedirect: true });
      axios
        .post(`/api/campaign/update/${this.props.campaign._id}`, {
          name: parsedCampaign.title,
          questions: JSON.stringify(this.surveyCreator.text),
          questioncolumns:dynamicCol
        })
        .then((res) => {
          if (res.status === 201) {
            this.setState({ IsRedirect: true });
            message.success("Campaign updated successfully.", 1);
          }
        })
        .catch((err) => {
          message.error("Campaign update failed. Try again!", 2);
        });
    }
  };
}

export default withRouter(SurCreator);
