import React, { Component } from "react";
import { Table, Tabs, Tag } from "antd";
import { PicRightOutlined, IdcardOutlined } from "@ant-design/icons";


const { TabPane } = Tabs;

const emailColumns = [
  {
    title: "Email",
    dataIndex: "email",
    width: 300,
  },
  {
    title: "Date",
    dataIndex: "date",
    width: 300,
  },
  {
    title: "Completed",
    dataIndex: "isCompleted",
    render: (val) =>
      val ? <Tag color={"green"}>Yes</Tag> : <Tag color={"volcano"}>No</Tag>,
    width: 100,
  },
];
function replacer(key, value) {
  if (typeof value === "boolean" || typeof value === "number") {
    return String(value);
  }
  return value;
}
export default class SurveyResult extends Component {
  state = {
    columnHeader: [],
    data: [],
    userEmailData: [],
    userEmailInfo: [],
  };

  componentWillMount() {
    const userEmail = this.props.userEmailData;
    let newUserEmail = [];
    userEmail.forEach((element) => {
      var newElement = {
        email: element.email,
        date: new Date(element.date).toLocaleString(),
        isCompleted: element.isCompleted,
      };
      newUserEmail.push(newElement);
    });
    this.setState({ userEmailData: userEmail, userEmailInfo: newUserEmail });
    
    // if(this.props.resultDate.length > 0){
    // const results = this.props.resultDate[0];
    // let headerItems = [
    //   { title: "Date", dataIndex: "date" },
    //   { title: "Email", dataIndex: "email" },
    // ];
    // for (var val in JSON.parse(results.result)) {
    //   headerItems.push({ title: val, dataIndex: val });
    // }

    if(this.props.campaignColHeader !== undefined && this.props.campaignColHeader.length > 0){
    const results = this.props.campaignColHeader[0].questioncolumns.split(",");
    let headerItems = [
      { title: "Date", dataIndex: "date" },
      { title: "Email", dataIndex: "email" },
    ];
    for (let index = 0; index < results.length; index++) {
      headerItems.push({ title: results[index], dataIndex: results[index] });
    }

    //dynamic rows
    let tableData = this.props.resultDate;
    let newarrValue = [];
    tableData.forEach((element) => {
      var newElement = {
        email: element.email,
        date: new Date(element.date).toLocaleString(),
      };
      var res = JSON.parse(element.result, replacer);
      var newObj = Object.assign({}, newElement, res);
      newarrValue.push(newObj);
    });
    this.setState({ columnHeader: headerItems, data: newarrValue });
  }
  else
  {
    this.setState({ columnHeader: "", data: "" });
  }
  }
  render() {
    return (
      <div>
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <PicRightOutlined />
                Response
              </span>
            }
            key="1"
          >
            <Table
              rowKey={this.state.data.length > 0 ? "date" : ""}
              columns={this.state.columnHeader}
              dataSource={this.state.data}
              size="middle"
              bordered="true"
              pagination={{ position: ["bottomRight"] }}
            />
          </TabPane>
          <TabPane
            tab={
              <span>
                <IdcardOutlined />
                Email
              </span>
            }
            key="2"
          >
            <Table
              rowKey="date"
              dataSource={this.state.userEmailInfo}
              columns={emailColumns}
              size="middle"
              bordered="true"
              pagination={{ position: ["bottomRight"] }}
            />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
