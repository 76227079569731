import React from "react";
import { connect } from "react-redux";
import { Table, Button, Divider } from "antd";
import DLL from "../../components/Dashboard/dashboardLayout";
import { withRouter } from "react-router-dom";

import { getContactusResponces } from "../../actions";

const columns = [
  {
    title: "Date",
    dataIndex: "date",
    width: 200,
  },
  {
    title: "First Name",
    dataIndex: "firstname",
    width: 150,
  },
  {
    title: "Last Name",
    dataIndex: "lastname",
    width: 150,
  },
  {
    title: "Email",
    dataIndex: "email",
  },
  {
    title: "Quotation",
    dataIndex: "requestQuotation",
  },
  {
    title: "Messsage",
    dataIndex: "message",
  },
];
class ContactUsView extends React.Component {
  state = {
    data: [],
  };
  componentWillMount() {
    this.props.getContactusResponces().then((resp) => {
      resp.forEach((element) => {
        element.date = new Date(element.date).toLocaleString();
      });
      this.setState({ data: resp });
    });
  }
  start = () => {
    this.props.getContactusResponces().then((resp) => {
      resp.forEach((element) => {
        element.date = new Date(element.date).toLocaleString();
      });
      this.setState({ data: resp });
    });
  };
  render() {
    return (
      <div>
        <DLL>
          <Button style={{ float: "left" }} type="primary" onClick={this.start}>
            Reload
          </Button>
          <br />
          <Divider />
          <Table
            rowKey="_id"
            bordered
            dataSource={this.state.data}
            columns={columns}
            pagination={{ position: ["topRight", "bottomRight"] }}
          />
        </DLL>
      </div>
    );
  }
}

function mapStateToProps({ contactUsResponces }) {
  return { contactUsResponces };
}
export default withRouter(
  connect(mapStateToProps, { getContactusResponces })(ContactUsView)
);
