import React from "react";
import { Divider, Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { withRouter, Link } from "react-router-dom";
import DashboardLayout from "../../components/Dashboard/dashboardLayout";
// import { LoadingOutlined } from "@ant-design/icons";
// import { Spin } from "antd";
import SurveyEditor from "../../components/Dashboard/surveyCreator";

// const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

class CreateNewCampaign extends React.Component {
  render() {
    return (
      <div>
        <DashboardLayout>
          <Link to="/dashboard">
            <Button
              type="primary"
              style={{ float: "left" }}
              icon={<LeftOutlined />}
            >
              Back
            </Button>
          </Link>
          <br />
          <Divider />
          {/* <Suspense
            fallback={
              <div className="example">
                <Spin indicator={antIcon} />
              </div>
            }
          > */}
          <SurveyEditor />
          {/* </Suspense> */}
        </DashboardLayout>
      </div>
    );
  }
}
export default withRouter(CreateNewCampaign);
