import { SUBMIT_PROJECT,DELETE_PROJECT,FETCH_PROJECT,UPDATE_PROJECT } from '../actions/types';

export default function (state = [], action) {
    switch (action.type) {
        case SUBMIT_PROJECT:
            return action.payload;
        case FETCH_PROJECT:
            return action.payload;
        case DELETE_PROJECT:
            return action.payload;
        case UPDATE_PROJECT:
            return action.payload;
        default:
            return state
    }
}