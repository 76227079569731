import React from 'react';
import { message } from 'antd';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import * as Survey from 'survey-react';
import { addSurveyResponse } from '../../actions';
import 'survey-react/survey.css';
import '../../App.css';
import '../../components/Product/ActiveSurvey.css';

class SurveyPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toresult: false,
			email: '',
			emailId: '',
			questionResults: '',
			issurveuSubmitted: false,
			result: [],
			activeCampaignId: '',
		};
		this.onComplete = this.onComplete.bind(this);
	}

	// componentWillUpdate()
	// {

	//   if (this.props.surveyResult.length !== 0)
	//   {
	//     this.setState({ issurveuSubmitted: !this.state.issurveuSubmitted });

	//   }

	// }

	onComplete(result) {
		var packet = {
			email: this.props.location.state.email,
			emailId: this.props.location.state.emailID,
			answer: JSON.stringify(result.data),
		};

		this.props
			.addSurveyResponse(this.props.location.state.campaignId, packet)
			.then((res) => {
				message.info(this.props.surveyResult.message, 1);
				this.setState({
					issurveuSubmitted: !this.state.issurveuSubmitted,
					result: result.data,
				});
			})
			.catch((err) => {
				message.error('Something went wrong.', 1);
			});
		// if (this.props.surveyResult.length !== 0)
		// {

		//    return <Redirect to={{ pathname: "/result", state: { questionResults: JSON.stringify(result)}}} />;
		// }
	}

	render() {
		if (this.props.location.state === undefined) {
			return (
				<Redirect
					to={{
						pathname: '/',
					}}
				/>
			);
		}
		if (this.state.issurveuSubmitted) {
			return (
				<Redirect
					to={{
						pathname: '/result',
						state: {
							questionResults: JSON.stringify(this.state.result),
						},
					}}
				/>
			);
		}

	Survey.Survey.cssType = 'darkblue';
    Survey.Survey.showProgressBar = 'top';
    
    var question = JSON.parse(this.props.location.state.campaign);
		var model = new Survey.Model(question);
		return (
			<div>
				<div>
					<div className="row-12">
						<div className="colbackground">
							<div className="container">
								<div className="card text-center">
									<div className="card-body">
										<div className="form-row">
											<div className="col-sm-12 my-1">
												<div className="surveyjs">
													<Survey.Survey model={model} onComplete={this.onComplete} />{' '}
												</div>{' '}
											</div>{' '}
										</div>{' '}
									</div>{' '}
								</div>{' '}
							</div>{' '}
						</div>{' '}
					</div>{' '}
				</div>{' '}
			</div>
		);
	}
}

function mapStateToProps({ loadActiveSurvey, surveyResult }) {
	return {
		loadActiveSurvey,
		surveyResult,
	};
}
export default withRouter(
	connect(mapStateToProps, {
		addSurveyResponse,
	})(SurveyPage),
);
