import React from "react";
import { Row } from 'antd';
import { getProductResponces } from '../../actions';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

class ProductList extends React.Component{
    componentDidMount() {
        this.props.getProductResponces();
      }

render() {
    return (
      <div className="site-card-wrapper container"> 
        <Row>
          {
             this.props.product.map(entery =>
              <div className="col-12 col-lg-4 col-md-6 pt-3" key={entery._id}>
              <div className="card-deck">
                <div className="card">
                <img height="200" alt="example" src={`${entery.image}`} />
                  <div className="card-body">
                    <h6 className="card-title">{entery.productname}</h6>
                    <p className="card-text">Description : {entery.description} <br/>
                    Price : ₹ {entery.price} {`/-`}</p>
                  </div>
                </div>
              </div>
              </div>
            )
          }
        </Row>
      </div>
    );
  }
}

  function mapStateToProps({ product }) {
    return { product };
  }
  export default withRouter(connect(mapStateToProps,{getProductResponces} )(ProductList));