import React from "react";
import { Avatar, Card, Row, Col } from "antd";
import { withRouter, Redirect } from "react-router-dom";
import NewSurveyIcon from "../../resources/new.svg";
// import SurveyCreator from "../../components/Dashboard/surveyCreator";
import "../../App.css";
const { Meta } = Card;

class SurveyCards extends React.Component {
  state = { visible: false, IsRedirect: false };
  constructor(props) {
    super(props);
    this.showModal = this.showModal.bind(this);
  }
  showModal() {
    this.setState({ IsRedirect: true });
    // this.props.history.push("/dashboard/campaign/new");
  }

  render() {
    if (this.state.IsRedirect) {
      return <Redirect to="/dashboard/campaign/new" />;
    } else {
      return (
        <div>
          <Row gutter={16}>
            <Col span={8}>
              <Card
                style={{ width: 300, marginTop: 16 }}
                onClick={this.showModal}
              >
                <Meta
                  avatar={<Avatar src={NewSurveyIcon} size={64} />}
                  title="Start new campaign"
                  description="Launch survey designer."
                />
              </Card>
            </Col>
          </Row>
        </div>
      );
    }
  }
}
export default withRouter(SurveyCards);
