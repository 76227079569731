import React from 'react';
import DocumentTitle from 'react-document-title';
// import ProductHeader from '../../components/Product/header/Header';
import Banner from '../../components/Product/Banner';
import PLL from '../../components/Product/ProductLandingLayout';
// import Footer from '../../components/Product/Footer';
import '../../App.css';
import 'antd/dist/antd.css';

class Home extends React.Component {
  render() {
    return (
      <div className="home-page">
        {/* <ProductHeader id="product-navbar" key="ProductHeader" /> */}
        <PLL>
        <Banner key="banner" />
        </PLL>
        {/* <Page1 key="page1" />
        <Page2 key="page2" /> */}
        {/* <Footer key="footer" />  */}
        <DocumentTitle title="Discoverhomes" />
      </div>
    );
  }
}
export default Home;
