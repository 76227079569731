import React from "react";
import { PageHeader,Form, Input, InputNumber, Button ,DatePicker,Table,Row,Col,Space,message,Popconfirm} from 'antd';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Resizer from 'react-image-file-resizer';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { addProduct,getProductResponces,deleteProduct,updateProduct } from "../../actions";
import moment from "moment";

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  class Products extends React.Component{

    formRef = React.createRef();

    state = {
      columns :[],
      productData: [],
      selectedFile: null,
      fileName:''
    };

    resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
    });

    onChange = async (e) => {
      this.setState({selectedFile:null,fileName:''});
      const param = e.target.files[0];
      const image = await this.resizeFile(param);
      this.setState({selectedFile:image,fileName :param.name });
    }
    
    componentDidMount(){
      this.setState({columns : [
        {
          title: "Sr No",
          dataIndex: "srno",
          key: 'srno',
          width: 50
        },
        {
          title: "Name",
          dataIndex: "productname",
          key: 'name',
          width: 120,
        },
        {
          title: "Description",
          dataIndex: "description",
          key: 'description',
          width: 120,
        },
        {
          title: "Price",
          dataIndex: "price",
          key: 'price',
          width: 120,
        },
        {
          title: 'Action',
          key: 'action',
          // dataIndex: 'action',
          render: (text, record) => (
            <Space size="middle">
              <Button type="link" size={"small"} onClick={() => this.editProductInfo({record})}>Edit</Button>
              <Popconfirm title="Are you sure to delete this Record？" placement="top" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => this.deleteProductInfo({record})}><Button type="link" size={"small"} >Delete</Button></Popconfirm>
            </Space>
          ),
          width: 70
          }
      ]})
      this.getProductInfo();
    }

    getProductInfo = () => {
      this.props.getProductResponces().then((result) => {
        if (result.length > 0) {
          var cnt = 1;
          var gridData = [];
          result.forEach(element => {
            gridData.push({
              srno : cnt++,
              key : cnt,
              productId : element._id,
              productname : element.productname,
              description :element.description,
              price :element.price,
              file : element.image,
              filename : element.filename,
            })
          });
          this.setState({ productData: gridData });
          this.render();
        } 
        else
        {
          this.setState({ productData: null });
          this.render();
        }
      });;
    }

    addProductInfo = (values) => {
      if (this.state.selectedFile === undefined || this.state.selectedFile === null) {
        values.file = ''
      }
      else
      {
        values.file = this.state.selectedFile;
      }
      if (this.state.fileName === undefined || this.state.fileName === null) {
        values.fileName = ''
      }
      else
      {
        values.fileName = this.state.fileName;
      }

      const formData = new FormData();
      formData.append('file',this.state.selectedFile);
      formData.append('productname',values.productname);
      formData.append('description',values.description);
      formData.append('price',values.price);
      formData.append('filename',this.state.fileName);

      if (values.productId !== undefined && values.productId !== null) {
        formData.append('productId',values.productId);
        this.props.updateProduct(formData).then((result) => {
          if (result.message !== undefined) {
            message.info(this.props.products.product.message, 1);
          } 
        });
      }
      else{
        this.props.addProduct(formData).then((result) => {
          if (result.message !== undefined) {
            message.info(this.props.products.product.message, 1);
          } 
        });
      }
      this.formRef.current.resetFields();
      this.setState({selectedFile:null,fileName:''});
      this.getProductInfo();
    }


    editProductInfo = (values) =>{
      this.formRef.current.setFieldsValue({
        productId : values.record.productId,
        productname: values.record.productname,
        description: values.record.description,
        price: values.record.price,
        file :values.record.file
      });
      if (values.record.file === "null") {
        this.setState({selectedFile:null,fileName:''});
      }
      else{
        this.setState({selectedFile:values.record.file,fileName:values.record.filename});
      }
      
    }

    deleteProductInfo = (value) => {
      if (value.record.productId !== undefined) {
        this.props.deleteProduct(value.record.productId).then((result) =>{
          if (result.message !== undefined) {
            message.info(this.props.products.product.message, 1);
            this.getProductInfo();
          } 
        })
      }else{
        message.warning("product id not found", 1);
      }
    }

    resetFields = () => {
      this.formRef.current.resetFields();
      this.setState({selectedFile:null});
    }

      render(){
      return(
          <div>
              <PageHeader title="Products"/>
                <Form {...layout} ref={this.formRef} name="nest-messages"  onFinish={(values) => { this.addProductInfo(values);}}>
                <Row justify="start">
                <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                <Form.Item name="productId" label="Product Id" hidden={true}>
                  <Input />
                </Form.Item>
                  <Form.Item name="productname" label="Name" rules={[
                      {
                        required: true,
                        message: 'Please select product name!'
                      },
                      {
                        pattern: /^.{1,20}$/,
                        message: "Value should be less than 20 character",
                      },
                    ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item name="description" label="Description" rules={[
                      {
                        required: true,
                        message: 'Please select description!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item name="price" label="Price" rules={[
                      {
                        type: 'number',
                        // min: 0,
                        // max: 99,
                        required: true,
                        message: 'Please select price!',
                      },
                    ]}
                  >
                    <InputNumber  style={{width: "50%" }}/>
                  </Form.Item>
                  </Col>
                  <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <Form.Item label="Logo" >
                  <Input type="file" name="file" onChange={this.onChange} accept="image/*" value=""/>
                  <input type="hidden" name="filename" />
                  <br/>
                  {this.state.selectedFile ? <img alt="" src={`${this.state.selectedFile}`} height="100px" width="150px"/> : ''}
                 </Form.Item>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                  <Button type="ghost" htmlType="reset" onClick={() => this.resetFields()} >
                      Reset
                    </Button>  
                  <Button type="primary" htmlType="submit" style={{marginRight:"10px"}}>
                      Submit
                    </Button>
                  </Form.Item>
                  </Col>
                  </Row>
                </Form>
            <PageHeader title="Product Details"/>
              <Table  bordered dataSource={this.state.productData} columns={this.state.columns} pagination={{ position: [ "bottomRight"] }} scroll={{  y: 300 }}/>
          </div>
        )
      }
  }

  
function mapStateToProps(products) {
    return { products };
  }
  
  export default withRouter(connect(mapStateToProps, {  addProduct,getProductResponces,deleteProduct,updateProduct })(Products));
  