import React from "react";
import { Link } from "react-router-dom";
import {  Menu, Grid } from "antd";
import "./header.css";


const { useBreakpoint } = Grid;

const RightHeader = () => {
 
    const { md } = useBreakpoint();
    return (
        <Menu mode={md ? "horizontal" : "inline"}>
          <Menu.Item key="login" className="rightLoginMenu">
            <Link to="/login" />
            Login
          </Menu.Item>
        </Menu>
     
    );
  
}
export default RightHeader;