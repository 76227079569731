import React from "react";
import {withRouter} from 'react-router-dom';
import Header from "./header/Header";
import PFooter from "./ProductFooter";
import SocialMediaLink from "./SocialMediaLinks";

const PHeader = withRouter(props => <Header {...props}/>);
export default class PLL extends React.Component {

    render(){
        return(
            <div>
            <PHeader />
            <SocialMediaLink/>
            {this.props.children}
            <PFooter />
            </div>
        );
    }
}