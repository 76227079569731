import React, { Component } from 'react';
import { connect } from "react-redux";
import { logoutUser } from "../../actions";
import { withRouter, Redirect } from 'react-router-dom';



class Logout extends Component {
  constructor() {
    super();
    this.state = {
      redirect : false,
    };
  }
    componentDidMount() {
        this.props.logoutUser();
      }
    
  componentWillReceiveProps(nextProps) {

    if (nextProps.auth.isAuthenticated) {
     this.setState({redirect : true});     

    }

    if (nextProps.errors) {
    //   this.setState({
    //     errors: nextProps.errors
    //   });
    }
  }

    render() {
 
    if(this.state.redirect)
    {
       return(<Redirect to="/login" />);
    }
    return(<div>

    </div>)

}
}
  
  const mapStateToProps = state => ({
    auth: state.auth,
  });
  
  export default withRouter(connect(mapStateToProps, { logoutUser } )(Logout));
  
