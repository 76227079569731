import React from "react";
import AboutUs from "../../components/Aboutus/index";
import PLL from "../../components/Product/ProductLandingLayout";

export default class frmAboutUS extends React.Component {
  render() {
    return (
      <div>
        <PLL>
          <AboutUs />
        </PLL>
      </div>
    );
  }
}
