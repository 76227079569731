import React, { Component } from "react";
import { Drawer, Button } from "antd";
import LeftHeader from './LeftMenu';
import RightHeader from './RightMenu';
import { UnorderedListOutlined } from "@ant-design/icons";

import logo from "../../../resources/discoverhomeslogo.jpeg";
import "./header.css";


class ProductHeader extends Component {
	state = {
		current: 'mail',
		visible: false
	}
	showDrawer = () => {
		this.setState({
			visible: true,
		});
	};

	onClose = () => {
		this.setState({
			visible: false,
		});
	};

  render() {    
    return (
      <nav className="menuBar">
      <div>
        <a href="/">
        <img src={logo} className="headerlogo" alt="Discoverhomes" />
        </a>
      </div>
      <div className="menuCon">
        <div className="leftMenu">
          <LeftHeader locations={this.props.location.pathname}/>
        </div>
        <div className="rightMenu">
          <RightHeader />
        </div>
        <Button  icon={<UnorderedListOutlined style={{color:"#111"}}/>}className="barsMenu" type="primary" onClick={this.showDrawer}>
     
     
        </Button>
        <Drawer
          title="Menu"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <LeftHeader locations={this.props.location.pathname}/>
          <RightHeader />
        </Drawer>

      </div>
    </nav>

    );
  }
}
export default ProductHeader;