import React, { Component } from "react";
import { Table, Card } from "antd";
import { Redirect } from "react-router-dom";
import { CheckCircleFilled } from "@ant-design/icons";
import "./index.css";
import PLL from "../../components/Product/ProductLandingLayout";

// const { Title } = Typography;

const columns = [
  {
    title: "Index",
    dataIndex: "index",
    width: "2%",
  },
  {
    title: "Questions",
    dataIndex: "questions",
  },
  {
    title: "Answers",
    dataIndex: "ans",
  },
];
export default class Result extends Component {
  render() {
    if (this.props.location.state === undefined) {
      return (
        <Redirect
          to={{
            pathname: "/",
          }}
        />
      );
    }
    let result = JSON.parse(this.props.location.state.questionResults);
    let data = [];
    let indexCounter = 1;
    for (var val in result) {
      data.push({
        questions: val,
        ans: result[val].toString(),
        index: indexCounter,
      });
      indexCounter++;
    }
    return (
      <div>
        <PLL>
          <div className="resultContainer">
            <div className="thankyouNote">
              <CheckCircleFilled
                style={{
                  color: "#52c41a",
                  fontSize: "32px",
                  paddingBottom: "24px",
                }}
              />
              <p style={{ padding: "8px" }}>
                Thank you for letting us know your preference. we will contact
                you soon!
              </p>
              {/* <Title> Thank You!</Title> */}
            </div>
            <Card className="cardLayout">
              <Table
                rowKey="index"
                columns={columns}
                bordered="true"
                dataSource={data}
                size="middle"
                pagination={{ position: ["bottomLeft"] }}
              />
            </Card>
          </div>
        </PLL>
      </div>
    );
  }
}
