import React from 'react';
import EmailView from '../../components/Dashboard/emailView';
import Header from '../../components/Product/header/Header';

// import '../../App.css';


export default class frmEmailView extends React.Component {
    render() {
        return (
            <div>
                <Header />
                <EmailView />
                <div className="home-page">
                    {/* <Footer /> */}
                </div>
            </div>
        )
    }
}