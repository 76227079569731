import React from "react";
import { PageHeader,Form, Input, Button ,Table,Row,Col,message,Space,Popconfirm } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Resizer from 'react-image-file-resizer';
import { addStudio,getStudioResponces,deleteStudiobyId,updateStudio } from "../../actions";
import "./index.css";

const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  class Studio extends React.Component{
    formRef = React.createRef();

    state = {
      columns :[],
      studioData: [],
      selectedFile: null,
      fileName:''
    };

     resizeFile = (file) => new Promise(resolve => {
      Resizer.imageFileResizer(file, 300, 300, 'JPEG', 100, 0,
      uri => {
        resolve(uri);
      }, 'base64' );
    });

    onChange = async (e) => {
      this.setState({selectedFile:null,fileName:''});
      const param = e.target.files[0];
      const image = await this.resizeFile(param);
      this.setState({selectedFile:image,fileName :param.name });
      // var reader = new FileReader();
      // reader.readAsDataURL(image);
      // reader.onload = (e) =>{
      //   debugger;
      //   this.setState({selectedFile:e.target.result});
      // }
    }

    componentDidMount(){
      this.setState({ columns: [
        {
          title: "Sr No",
          dataIndex: "srno",
          key: 'srno',
          width: 50
        },
        {
          title: "Title",
          dataIndex: "title",
          key: 'title',
          width: 100,
        },
        {
          title: "Address",
          dataIndex: "address",
          key: 'address',
          width: 200,
        },
        {
          title: "Link",
          key: 'gmaplink',
          dataIndex: "gmaplink",
          width: 150,
        },
        {
        title: 'Action',
        key: 'action',
        // dataIndex: 'action',
        render: (text, record) => (
          <Space size="middle">
            <Button type="link" size={"small"} onClick={() => this.editStudioInfo({record})}>Edit</Button>
            <Popconfirm title="Are you sure to delete this Record？" placement="top" icon={<QuestionCircleOutlined style={{ color: 'red' }} />} onConfirm={() => this.deleteStudioInfo({record})}><Button type="link" size={"small"} >Delete</Button></Popconfirm>
          </Space>
        ),
        width: 70
        }
      ] });
      this.getStudioInfo();
    }
    

    getStudioInfo = () => {
      this.props.getStudioResponces().then((result) => {
        if (result.length > 0) {
          var cnt = 1;
          var gridData = [];
          result.forEach(element => {
            gridData.push({
              srno : cnt++,
              key : cnt,
              recordId : element._id,
              title : element.title,              
              address : element.address,
              gmaplink :element.gmaplink,
              file : element.image,
              filename : element.filename,
            })
          });
          this.setState({ studioData: gridData });
          this.render();
        } 
        else
        {
          this.setState({ studioData: null });
          this.render();
        }
      });;
    }
    
    addStudioInfo = (values) => {
      if (this.state.selectedFile === undefined || this.state.selectedFile === null) {
        values.file = ''
      }
      else
      {
        values.file = this.state.selectedFile;
      }
      if (this.state.fileName === undefined || this.state.fileName === null) {
        values.fileName = ''
      }
      else
      {
        values.fileName = this.state.fileName;
      }
      const formData = new FormData();
      formData.append('file',this.state.selectedFile);
      formData.append('title',values.title);
      formData.append('address',values.address);
      formData.append('gmaplink',values.gmaplink);
      formData.append('filename',this.state.fileName);
      

      if (values.recordId !== undefined && values.recordId !== null) {
        formData.append('recordId',values.recordId);
        this.props.updateStudio(formData).then((result) => {
          if (result.message !== undefined) {
            message.info(this.props.studio.studio.message, 1);
          } 
        });  
      }
      else{
        this.props.addStudio(formData).then((result) => {
          if (result.message !== undefined) {
            message.info(this.props.studio.studio.message, 1);
          } 
        });
      }
      this.formRef.current.resetFields();
      this.setState({selectedFile:null,fileName:''});
      this.getStudioInfo();
    }

    editStudioInfo = (values) =>{
      this.formRef.current.setFieldsValue({
        recordId : values.record.recordId,
        title: values.record.title,
        address: values.record.address,
        gmaplink: values.record.gmaplink,
        file :values.record.file
      });
      if (values.record.file === "null") {
        this.setState({selectedFile:null,fileName:''});
      }
      else{
        this.setState({selectedFile:values.record.file,fileName:values.record.filename});
      }
    }

    deleteStudioInfo = (value) => {
      if (value.record.recordId !== undefined) {
        this.props.deleteStudiobyId(value.record.recordId).then((result) =>{
          if (result.message !== undefined) {
            message.info(this.props.studio.studio.message, 1);
            this.getStudioInfo();
          } 
        })
      }else{
        message.warning("record id not found", 1);
      }
  }

  resetFields = () => {
    this.formRef.current.resetFields();
    this.setState({selectedFile:null});
  }

    render(){
      return(
          <div>
              <PageHeader title="Studio"/>
                <Form {...layout} ref={this.formRef} name="nest-messages"  onFinish={(values) => { this.addStudioInfo(values);}}>
                <Row justify="start">
                <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                <Form.Item name="recordId" label="Record Id" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="title" label="Title" rules={[
                  {
                    required: true,
                    message: 'Please enter title!',
                  },
                  {
                    pattern: /^.{1,20}$/,
                    message: "Value should be less than 20 character",
                  },
                ]} >
                  <Input />
                </Form.Item>
                  <Form.Item name="address" label="Address" rules={[
                      {
                        required: true,
                        message: 'Please select address!',
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                  </Col>
                  <Col xs={{ span: 18 }} md={{ span: 12 }} xl={{ span: 12 }}>
                  <Form.Item name="gmaplink" label="Google Link" rules={[
                    {
                      required: true,
                      message: 'Please select google map link!',
                    },
                  ]}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Logo" >
                  <Input type="file" name="file" onChange={this.onChange} accept="image/*" value=""/>
                  <input type="hidden" name="filename" />
                  <br/>
                  {this.state.selectedFile ? <img alt="" src={`${this.state.selectedFile}`} height="100px" width="150px"/> : ''}
                 </Form.Item>
                  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                  <Button type="ghost" htmlType="reset" onClick={() => this.resetFields()} >
                  Reset
                </Button> 
                  <Button type="primary" htmlType="submit" style={{marginRight:"10px"}}>
                      Submit
                  </Button>
                  </Form.Item>
                  </Col>
                  </Row>
                </Form>
            <PageHeader title="Studio Details"/>
              <Table  bordered dataSource={this.state.studioData} 
                      columns={this.state.columns} pagination={{ position: [ "bottomRight"] }}  scroll={{  y: 300 }}/>
          </div>
        )
      }
  }

  
function mapStateToProps(studio) {
    return { studio };
  }
  
  export default withRouter(connect(mapStateToProps, { addStudio ,getStudioResponces,deleteStudiobyId,updateStudio})(Studio));
  