import React from "react";
import { Link } from "react-router-dom";
import { Menu, Grid,Typography } from "antd";
import "./header.css";

const { Text } = Typography;


const { useBreakpoint } = Grid;

const handleSelectIndex = (path) => {

    
    var routes = [" ", "/", "/viewProjects","/viewProducts","/viewStudio", "/viewContact","/viewAboutus","/login"];
    var selectedIndex = "";
    if (path === "/")
      selectedIndex = `['` + routes.indexOf("/") + `']`;
    else if (path === "/contact")
      selectedIndex = `['` + routes.indexOf("/contact") + `']`;
    else if (path === "/viewProjects")
      selectedIndex = `['` + routes.indexOf("/viewProjects") + `']`;
    else if (path === "/viewProducts")
      selectedIndex = `['` + routes.indexOf("/viewProducts") + `']`;
    else if (path === "/viewStudio")
      selectedIndex = `['` + routes.indexOf("/viewStudio") + `']`;
    else if (path === "/aboutus")
      selectedIndex = `['` + routes.indexOf("/aboutus") + `']`;
    else if (path === "/login")
      selectedIndex = `['` + routes.indexOf("/login") + `']`;
      


    return selectedIndex;
  };

const LeftHeader = (locations) => {

    const { md } = useBreakpoint();
    return (
        <>
        <Menu theme="light" mode={md ? "horizontal" : "inline"}  selectedKeys={handleSelectIndex(locations.locations.toString())}>
          <Menu.Item key="1">
            {" "}
            <Link to="/" />
            <Text strong>Advanced Survey</Text>
          </Menu.Item>
          <Menu.Item key="2">
          {" "}
            <Link to="/viewProjects" />
            <Text strong>Homes</Text>
          </Menu.Item>
          <Menu.Item key="3">
          {" "}
            <Link to="/viewProducts" />
            <Text strong>Decor</Text>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/viewStudio" />
            <Text strong>ADH Studio</Text>
          </Menu.Item>
            <Menu.Item key="5">
              <Link to="/viewContact" />
              <Text strong>Contact</Text>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/viewAboutus" />
              <Text strong>About</Text>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/viewContact" />
             <Text strong>Ask Quote</Text>
            </Menu.Item>
        </Menu>
      </>
    );

}
export default LeftHeader;