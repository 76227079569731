import { SUBMIT_STUDIO,FETCH_STUDIO,DELETE_STUDIO,UPDATE_STUDIO } from '../actions/types';

export default function (state = [], action) {
    switch (action.type) {
        case SUBMIT_STUDIO:
            return action.payload;
        case FETCH_STUDIO:
            return action.payload;
        case DELETE_STUDIO:
            return action.payload;
        case UPDATE_STUDIO:
            return action.payload;
        default:
            return state
    }
}