import React, { Component } from 'react';
import Login from '../../components/Login/frmLogin';

export default class LoginPage extends Component {


  //Check for the Property IsUserValid then renedr GetPassword Component
  render() {

    return (
      
      <div>
            
      <Login />



      </div>
    )
  }
}