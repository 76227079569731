import React from "react";
import { Result, Button } from 'antd';
import {Redirect} from 'react-router-dom';


export default class PNF extends React.Component{
    state={
redirect:false
    }

    changeState(){
        this.setState({redirect:true});
    }

render(){
    if (this.state.redirect) {
        return <Redirect to="/" />;
      }
    return(
        <div>
        <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Button type="primary" style={{float:"initial"}} onClick={() => this.changeState()}>Back Home</Button>}/>
  </div>
    )
}
}
