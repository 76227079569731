export const FETCH_SURVEYS = "fetch_surveys";
export const FETCH_SURVEY_BY_ID = "fetch_survey_by_id";
export const ACTIVATE_SURVEY_BY_ID = "activate_survey_by_id";
export const LOAD_ACTIVE_SURVEY = "load_active_survey";
export const SUBMIT_SURVEY_RESULT = "submit_survey_result";
export const SUBMIT_USER_EMAIL = "submit_user_email";
export const SUBMIT_CONTACTUS = "submit_contactus";
export const FETCH_SURVEY_RESULT_BY_ID = "fetch _survey_result_by_id";
export const DOWNLOAD_SURVEY_RESULT_BY_ID = "download _survey_result_by_id";
export const FETCH_SURVEYCOUNT_BY_ID = "fetch_surveycount_by_id";
export const FETCH_CONTACTUS_RESPONCES = "fetch_contact_us_responces";
export const FETCH_EMAIL_RESPONSE_BY_ID = "fetch_email_response_by_id";
export const GET_ERRORS = "GET_ERRORS";
export const USER_LOADING = "USER_LOADING";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const FETCH_CAMPAIGN_COLUMN = "FETCH_CAMPAIGN_COLUMN";
export const SUBMIT_STUDIO = "submit_studio";
export const FETCH_STUDIO = "fetch_studio";
export const DELETE_STUDIO = "delete_studio";
export const UPDATE_STUDIO = "update_studio";
export const SUBMIT_PROJECT = "submit_project";
export const FETCH_PROJECT = "fetch_project";
export const DELETE_PROJECT = "delete_project";
export const UPDATE_PROJECT = "update_studio";
export const SUBMIT_PRODUCT = "submit_product";
export const FETCH_PRODUCT = "fetch_product";
export const DELETE_PRODUCT = "delete_product";
export const UPDATE_PRODUCT = "update_product";