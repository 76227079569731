import React from 'react';
import SurveyPage from '../../components/Product/ActiveSurvey';
import PLL from '../../components/Product/ProductLandingLayout';
import { Card } from 'antd';
import '../../App.css';
import 'antd/dist/antd.css';
import './index.css';
export default class frmActiveSurvey extends React.Component 
{
    render()
    {
        return(
            <div>
            <PLL>
            <Card className="surveryContainer">
            <SurveyPage />    
            </Card>            
            </PLL>
                
            </div>
        );
    }
}